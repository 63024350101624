<template>
  <div>
    <section class="hero is-medium is-dark has-background">
      <img
        :src="`./img/background/domino_wallpaper_blue.png`"
        class="is-transparent hero-background"
      />
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-1 is-size-2-mobile">Hilfsmittel</h1>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h1 class="title">Anleitungen für die Verwendung von Hilfsmitteln</h1>

        <ul>
          <li v-for="(tool, index) in tools" :key="tool.name">
            <a :href="`#${tool.title}`" class="is-size-4">{{
              `${1 + index}. ${tool.title}`
            }}</a>
          </li>
        </ul>
        <hr />
        <div class="content is-medium">
          <div v-for="(tool, index) in tools" :key="index" :id="tool.title">
            <h1 class="title is-3">{{ tool.title }}</h1>
            <img
              :src="getToolImage(tool)"
              :alt="`${tool.title} Illustration`"
            />
            <video autoplay playsinline muted loop>
              <source
                :src="getToolVideo(tool)"
                type="video/mp4"
                :alt="`${tool.title} Video`"
              />
              Your browser does not support the video tag.
            </video>
            <hr />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
img,
video {
  width: 100%;
}
</style>
<script>
export default {
  metaInfo: {
    title: "Anleitungen für Hilfsmittel",
  },
  data: function () {
    return {
      tools: [
        {
          title: "Seitenwechsler",
          img: "seitenwechsler_erklaerung.png",
          video: "seitenwechsler.mp4",
        },
        {
          title: "Kamm",
          img: "kamm_erklaerung.png",
          video: "kamm_original.mp4",
        },
        {
          title: "Brücke",
          img: "bruecke_erklaerung.png",
          video: "bruecke.mp4",
        },
      ],
    };
  },
  methods: {
    getToolImage(tool) {
      return require(`@/assets/img/tools/${tool.img}`);
    },
    getToolVideo(tool) {
      return require(`@/assets/video/${tool.video}`);
    },
  },
};
</script>